*{
  font-family: 'IBM Plex Sans', sans-serif;
  max-width: 100vw;
  outline: 0;
}
body{
  overflow-x: hidden;
}
.container-fluid{
  padding-right: 2.5rem !important;
}
p{
  margin-block-start: 0;
  line-height: 150%;
}
.App{
  display:flex;
  flex-direction: column;
  align-items: center;
  color: #222;
  width: 100vw;
}

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}
select {
  background: transparent;
}

ul{
  margin-left: 0 ;
  padding-left: 0;
}

.top-bar{
  display:flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.85);
  box-shadow: 0px 10px 18px rgba(8, 49, 96, 0.2);
  position: fixed;
  width: 100vw;
  z-index: 99;
  height: 75px;
}
.top-bar-content{
  display:flex;
  justify-content: space-between;
  align-items: center;
  
}

.d-none {display:none!important;}
.d-block {display:block!important;}
.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}
.form-check-input {
  position: absolute;
  margin-left: -1.25rem;
  margin-top: .3rem;
  box-sizing: border-box;
  padding: 0;
  width: auto;
  min-height: auto;
}
.form-check-label {
  margin: 0;
  display: inline-block;
  cursor: pointer;
}
.m-0 {margin:0!important;}
.ml-0 {margin-left:0!important;}
.opacity-50 {opacity:0.5;}

.logo>img{
  width: 185px;
}
nav>ul{
  display:flex;
  list-style-type: none;
  list-style: none;
  padding-inline-start: 0
}
nav>.nav-ul-normal{
  height: 75px!important;
  display:flex;
  align-items: center;
  margin: 0;
}
nav>.nav-ul-normal>li{
  padding: 0 12px;
  height: 100%;
  display:flex;
  align-items: center;
}
nav>ul>li>a{
  color:#0E6EB6;
  text-decoration: none;
  font-size: 16px;
}
nav>ul>li[id*=nav-]:hover {
  padding-bottom: 25px;
  padding-top: 25px;
}
nav>ul>li[id*=nav-]:hover > .hover-menu {
  opacity: 1;
  display: flex;
}
.nav-right{
  display:flex;
  align-items: center;
}
.abs-nav{
  position: fixed;
  left:0;
  top:75px;
  width: 100%;
  z-index: 99;

  background-color:#0E6EB6;
  animation: slide-in .4s ease-in forwards;
  
  animation: slide-in 1s ease-out forwards;
}
@media only screen and (max-width:48em){
  .top-bar > .container-fluid{
    width: 100%;
  }
}
@media only screen and (min-width: 48em){
  .nav-ul-condensed{
    display:none;
  }
}
@keyframes slide-in{
  0%{
    transform: scaleY(0), translateY(0)
  }
  100%{
    transform: scaleY(1), translateY(1)
  }
}
.abs-nav > nav > ul {
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.abs-nav > nav > ul > li > a{
  color: #fff
}
.abs-nav > nav > ul > li{
  margin-bottom: 20px;
}


button{
  outline: none;
  cursor: pointer;
}
.lang-btn{
  border: 0;
  color: #0E6EB6;
  font-size: 16px;
  background: transparent;
}
.lang-btn-selected{
  color:#fff;
  border: 0;
  border-radius: 4px;
  background-color: #0E6EB6;
  height: 28px;
  font-size: 16px;
}
.career-page-content{
  box-sizing: border-box;
}
.lang-btn-abs{
  color: #fff;
}
.lang-btn-selected-abs{
  color:#0E6EB6;
  border: 0;
  border-radius: 4px;
  background-color: #fff;
  height: 28px;
  font-size: 16px;
}
.lang-selector{
  display: flex;
  justify-content: center;
  align-items: center;
}
.separator{
  margin-right: 15px;
  color: #0E6EB6
}
.country-selector{
  border: 0;
  color:#0E6EB6;
  background: transparent;
  padding: 8px 0 ;
  appearance: none;
  border:none;
  font-size: 16px;
  padding-right: 16px;
}
.country-select-wrapper {
  display:flex;
  /* justify-content: center; */
  align-items: center;
}
/* .country-select-wrapper > img {
  height: 16px;
  width: 16px;
  margin-right: 8px;
} */
.country-selector-ico{
  height: 16px;
  width: 16px;
  margin-right: 12px;
  padding: 6px;
  border-bottom: 2px solid transparent
}
.country-selector-ico.active{
  border-bottom: 2px solid #0E6EB6;
}
/* .country-select-wrapper:after {
    content: "";
    position: relative;
    margin-left: -12px;
    z-index: 5;
    pointer-events: none;
    color: #0E6EB6;
    margin-right: 12px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 5px 0 5px;
    border-color: #0e6db6 transparent transparent transparent;
} */

/*
  Footer
*/
footer{
  background: url(./images/footer-map.png), #0E6EB6;
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center;
  display:flex;
  justify-content: center;
  color: #fff;
  font-weight: 300;
  
  width: 100%;
  padding-top: 72px;
}

footer * h3{
  font-weight: 400;
  margin: 0;
}

footer * hr{
  border-top: 0px;
  border-color: rgba(255, 255, 255, 0.3);
}

footer * ul{
  list-style: none;
  list-style-type: none;
  line-height: 182%;
  padding-inline-start: 0
}
footer * div{
  margin-bottom: 1rem;
}
.copy-right-row{
  margin: 48px;
  font-size: 14px
}
/* Landing Page styles */
.landing-page-content{
  width: 100%;
  display:flex;
  flex-direction: column;
  align-items: center;
}

/* Hero */
.hero{
  overflow: hidden; 
  color:#fff;
  width: 100vw;
  display:flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 200px);
  background-size: cover;
  position: relative;
  min-height: 450px;
}
.hero-instance{
  height: 100%;
  position: absolute;
  transition: opacity .5s ease-in;
  width: 100%;
  background-size: cover;
  background-position: center center;
}
.hero-container{
  height: 100%;
  display:flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  text-shadow: rgba(0,0,0,0.7) 0 2px 3px;
}
.hero-text-block::before{
  content: '';
  background-color: rgba(112, 121, 130, 0.5);
  height: 100%;
  position: absolute;
  top:0;
  width: 2000px;
  left: -20px;
  z-index: -1
}
.hero-text-block > p {
  font-size: 1.25rem;
}
.hero-text-block{
  position: relative;
  padding: 0 20px;
  display:flex;
  flex-direction: column;
  margin-bottom: 150px;
  z-index: 50;
  /* transform: translateX(30%) */
}

.hero-navigator{
  position: absolute;
  bottom: 20px;
  display:flex;
}
.navigator-cirle{
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 8px;
  height: 12px !important;
  width: 12px !important;
  content: '';
  margin: 4px;
  transition: all .2s ease-in;
  cursor: pointer;
}
.navigator-cirle.navigator-circle-active{
  background-color: #0E6EB6;
}

@media only screen and (max-width:54em){
  .hero-text-block{
    width: 90%;
  }
  .hero-text-block{
    transform: translate(0);
  }
}
/*Main Card*/
.main-card-row{
  padding-top: 75px;
  padding-bottom: 75px;
}
.main-card-wrapper{
  padding: 10px;
}
.main-card-wrapper:nth-of-type(1) * .main-card-stripe, .main-card-wrapper:nth-of-type(1) * .main-card-btn{
  background-color: #2F4660
}
.main-card-wrapper:nth-of-type(3) * .main-card-stripe, .main-card-wrapper:nth-of-type(3) * .main-card-btn{
  background-color: #3CABFF
}

.main-card{
  min-height: 350px;
  box-shadow: 0px 10px 18px rgba(8, 49, 96, 0.2);
  padding: 50px !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.main-card > h2{
  font-weight: 400;
  font-size: 36px;
  margin-bottom: 0.5rem;
  height: 86px;
  display:flex;
  align-items: center;
}
.main-card > p {
  /* height: 100px; */
  margin-bottom: 50px;
}
.main-card-stripe{
  width: 50%;
  height: 10px;
  margin-bottom: 10px;
  background-color: #0E6EB6;
}
.main-card-btn{
  background-color: #0E6EB6;
  height: 32px;
  width: 167px;
  border-radius: 66px;
  color: #fff; 
  border: 0;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.5);
  transition: all .2s ease-in-out;
}
.main-card-btn:hover{
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.7);
  filter: contrast(1.3);
  /* height: 36px; */
}

.hued-row{
  background-color: #F6F9FC;
  width: 100%;
  display:flex;
  flex-direction: column;
  align-items: center;
}

.location-info{
  display:flex;
  align-items: flex-start;
}
.location-info > img{
  margin-right: 12px;
}
.location-row{
  margin: 50px 0;
}
/*
Helpers
*/
@media only screen and (max-width:48em){
  .hide-xs{
    display:none;
  }
}
@media only screen and (max-width: 62em){
.hide-sm{
    display:none;
  }
}
@media only screen and (max-width: 62em){
  .hide-md{
    display: none;
  }
}
@media only screen and (max-width: 62em){
  .hide-lg{
    display: block;
  }
}
@media only screen and (min-width: 62em){
  .hide-lg{
    display: none !important;
  }
}
a{
  color: #0E6EB6;
  text-decoration: none; 
}
.blue-heading{
  color: #0E6EB6;
  font-size: 18px;
}
.next-prev-btn{
  border: none;
  background: none;
  padding: 20px 20px 0px 20px;
}

textarea, input{
  border-color: rgba(101,115,135,0.20);
  border-width: 1px;
  border-radius: 4px;
  min-height: 45px;
  margin-top: 14px;
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
}
input[type="submit"]{
  border: 0;
  width: auto;
  float: right;
  color: #0E6EB6;
  background-color: #fff;
}
.contact-form{
  color:#0E6EB6;
}

.contact-row{
  margin-top: 50px;
  margin-bottom: 50px;
}
.testimonial-container{
  display:flex;
  flex-direction: column;
  justify-content: center;
  /* height: 100%; */
  transform: translateY(20px);
  margin-bottom: 25px;
}
.testimonial-wrapper{
  min-height: 250px;
  flex-direction: column;
  transform: translateY(-20px)
}
.testimonial-bg, .testimonial-bg-2{
  height: 90%;
  border-radius: 4px;
  width: 95%;
  box-shadow: 0px 10px 18px rgba(8, 49, 96, 0.2);
  padding-top: 20px;
  background-color: #fff;
  display:flex;
  justify-content: center;
}
.testimonial-content::before{
  content: ',,';
  font-family: serif;
  position: absolute;
  color: #0E6EB6;
  font-size: 40px;
  margin-top: -60px;
  background-color: #EDF3FA;
  display: flex;
  
  justify-content: center;
  align-items: center;
  width: 5px;
  height: 30px;
  padding-bottom: 25px;
  padding-left: 25px;
  padding-right: 25px;
  
  border-radius: 100%;
}
.testimonial-content{
  width: calc(95% - 80px);
  background-color: #fff;
  border-radius: 4px;
  padding: 40px;
  padding-bottom: 40px;
  max-height: 85%;
  background-color: #fff;
  box-shadow: 0px 10px 18px rgba(8, 49, 96, 0.2);
  transform: translateY(20px);
  transition: all .5s ease-in;
}
.testimonial-content>img{
  border-radius: 100%;
  margin-right: 20px;
}
.testimonial-text{
  font-style: italic;
  font-weight: 300;
}
.flex{
  display:flex;
}
.shop-row{
  margin-bottom: 75px;
}

/*li.alice-carousel__stage-item :not(.__cloned) {
	width: auto !important;
	margin-right: 1rem;
}*/

.benefit{
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 180px;
  text-align: center;
  box-sizing: border-box;
  padding: 0px 20px 40px 20px;
  /* hyphens: auto; */
}
@media only screen and (max-width:32em){
  .benefit{
    width: 140px;
  }
}

.benefit > h3{
  font-size: 15px;
}
.benefit > span{
  font-size: 14px;
}
.benefit-ico{
  background-color: #D9E5F450;
  border-radius: 100%;
  width: 72px;
  height: 72px;
  display:flex;
  justify-content: center;
  align-items: center;
}
.benefit-grid{
  display:flex;
  flex-wrap: wrap;
}
.career-split-pane{
  display:flex;

}
.left-split-pane, .right-split-pane{
  width: 50%;
  flex-basis: 50%;
}
.career-row{
  margin: 50px 0;
}
.position-entry{
  display:flex;
  justify-content: space-between;
}
.position-entry > a{
  min-width: 110px;
  margin-left: 50px;
}
hr{
  border: 1px solid #22222225;
  border-top: 0px;
}
.subservice{
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1rem;
}
.sub-service-card{
  width: 240px;
  height: 135px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  cursor: pointer;
  transition: all .1s ease-in;
  overflow: hidden;
  /* box-shadow: 0px 10px 18px rgba(8, 49, 96, 0.4), inset 0 0 0 600px #009FE360; */
  box-shadow: 0px 10px 18px rgba(8, 49, 96, 0.4);
}
.sub-service-card > h4 {
  margin: 0px;
  color: #fff;
  font-weight: 400;
  padding: 10px;
  width: 100%;
  background-color: rgba(14, 109, 182, 0.74);
}
.selected-subservice{
  transform: translateY(5px);
  /* box-shadow: 0px 10px 18px rgba(8, 49, 96, 0.0), inset 0 0 0 600px #009FE360; */
  box-shadow: 0px 10px 18px rgba(8, 49, 96, 0.0);
}

h2{
  margin-top: 0;
}
.container{
  padding-top: 50px;
  padding-bottom: 50px;
}
.sub-service-item-box{
  box-shadow: 0px 10px 18px rgba(8, 49, 96, 0.2);
  padding: 24px;
  border: 1px solid rgba(102, 114, 136, 0.2);
  border-radius: 4px;
  margin-bottom: 20px;
  display:flex;
  /* justify-content: center; */
  align-items: center;
  /* max-width: 340px; */
  box-sizing: border-box;
}
.sub-service-item-box > img{
  margin-right: 20px;
}
.subservice-description{
  margin-bottom: 25px;
  /* text-align: justify; */
}
.contact-card{
  box-shadow: 0px 10px 18px rgba(8, 49, 96, 0.2);
  padding: 24px;
  border-radius: 4px;
  margin-bottom: 25px;
  background-color: #fff;
}
.contact-card * img{
	min-width: 64px;
	width: 64px !important;
	height: 64px !important;
	display: block;
	margin-right: 20px;
	border-radius: 64px; 
}
.contact-upper{
  display:flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 15px;
  hyphens: auto;
}

/* Hamburger Things*/

.hamburger .line{
  width: 30px;
  height: 3px;
  background-color: #0E6EB6;
  display: block;
  margin: 8px auto;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.hamburger:hover{
  cursor: pointer;
}

/* ONE */

#hamburger-1.is-active .line:nth-child(2){
  opacity: 0;
}

#hamburger-1.is-active .line:nth-child(1){
  -webkit-transform: translateY(11px) rotate(45deg);
  -ms-transform: translateY(11px) rotate(45deg);
  -o-transform: translateY(11px) rotate(45deg);
  transform: translateY(11px) rotate(45deg);
}

#hamburger-1.is-active .line:nth-child(3){
  -webkit-transform: translateY(-11px) rotate(-45deg);
  -ms-transform: translateY(-11px) rotate(-45deg);
  -o-transform: translateY(-11px) rotate(-45deg);
  transform: translateY(-11px) rotate(-45deg);
}

.hover-menu{
  opacity:0;
  display: none;
  position: absolute;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 10px;
  top: 95px;
  border-radius: 4px;
  transform: translateX(-35%);
  box-shadow: 0px 10px 18px rgba(8, 49, 96, 0.2);
}

@media only screen and (max-width:75em) and (min-width:62.1em){
  .main-card{
    height: 400px;
  }
}
.hover-menu::before{
  content: '';
  position: absolute;
  background-color: transparent;
  border: 8px solid transparent;
  border-bottom: 10px solid rgba(255,255,255,0.8);
  height: 0px;
  width: 0px;
  top: -18px;
  left: calc(50% - 9px);
}
.hover-menu > a {
  margin: 4px;
  margin-left: 10px;
  margin-right: 10px;
}
.hover-menu > a {
  padding-top: 8px;
  padding-bottom: 8px;
}
.number-entry{
  text-align: center;
  color: #222;
}
.number-entry > div > span {
  color:#0E6EB6
}
.number-entry > div:nth-child(2){
  font-size: 32px;
  font-weight: bold;
  margin: 20px 0;
}
.numbers-row{
  /* background-image: url(./images/numbers-row-bg.png); */
  background-color: #D9E5F450;
  background-size: cover;
  background-position: center;
}
.quick-links{
  width: 100%;
  /* background-image: url(./images/numbers-row-bg.png); */
  background-color: #D9E5F450;
  background-size: cover;
  background-position: center;
  color: #222;
}

.quick-links * h3{
  margin-top: 0;
}
.quick-links * a{
  color: #222
}
.quick-link>a:hover{
  color: #0E6EB6;
}
.quick-link{
  margin-bottom: 25px
}

footer * a {
  color: #fff;
}

.top-bar * a.active{
  /* text-decoration: underline; */
  box-sizing: border-box;
  border-bottom: 2px solid #0E6EB6;
  padding-bottom: 6px;
  padding-top: 8px;
}
.top-bar * .abs-nav * a.active{
  border-bottom: 2px solid #fff;
}
.logo-link.active{
  border-bottom: 0 !important;
  padding-bottom: 6px;
  padding-top: 6px;
}


.gmaps{
  border: 0;
  border-radius: 4px;
  box-shadow: 0px 10px 18px rgba(8, 49, 96, 0.2);
  width: 100%;
  min-height: 400px;
  height: 100%;
  margin-top: 20px;
}

.location-heading{
  cursor: pointer;
  color: #222;
  margin-right: 20px;
  margin-bottom: 10px;
  transition: all .2s;
}
.location-heading:hover{
  color: #0E6EB6  ;
}
.location-heading.active{
  color: #0E6EB6;
}

.location-heading-wrapper{
  display:flex;
  flex-wrap: wrap
}

.partner-img{
  max-height: 42px;
  filter: saturate(0);
  /* opacity: 0.7; */
  padding: 20px;
}

.online-button-link{
  margin-bottom: 30px;
  margin-top: 30px;
}

.online-button-link > div{
  background-color: #D9E5F450;
  border-radius: 100%;
  width: 72px;
  height: 72px;
  display:flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}
.online-link-icon-alt{
  background-color: #fff !important
}

.online-button-link{
  display:flex;
  flex-direction: row;
  align-items: center;
  margin-left: 2rem;
}

iframe#repair-iframe, iframe#info-iframe {
  width: 100%;
  max-width: 100%;
  height: 400px;
  border: 0px;
  overflow: hidden;
}
iframe#repair-iframe.loaded, iframe#info-iframe.loaded {
  opacity: 1;
}