/* ibm-plex-sans-100 - latin-ext_latin */
@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 100;
    src: url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-100.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-100.woff2') format('woff2'), /* Super Modern Browsers */
        url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-100.woff') format('woff'), /* Modern Browsers */
        url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
        url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-100.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
}

/* ibm-plex-sans-100italic - latin-ext_latin */
@font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 100;
    src: url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-100italic.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-100italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-100italic.woff2') format('woff2'), /* Super Modern Browsers */
        url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-100italic.woff') format('woff'), /* Modern Browsers */
        url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-100italic.ttf') format('truetype'), /* Safari, Android, iOS */
        url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-100italic.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
}

/* ibm-plex-sans-200 - latin-ext_latin */
@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 200;
    src: url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-200.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-200.woff2') format('woff2'), /* Super Modern Browsers */
        url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-200.woff') format('woff'), /* Modern Browsers */
        url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
        url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-200.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
}

/* ibm-plex-sans-300 - latin-ext_latin */
@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-300.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-300.woff2') format('woff2'), /* Super Modern Browsers */
        url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-300.woff') format('woff'), /* Modern Browsers */
        url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
        url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-300.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
}

/* ibm-plex-sans-300italic - latin-ext_latin */
@font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 300;
    src: url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-300italic.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
        url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-300italic.woff') format('woff'), /* Modern Browsers */
        url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
        url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-300italic.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
}

/* ibm-plex-sans-regular - latin-ext_latin */
@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
        url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
        url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
        url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-regular.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
}

/* ibm-plex-sans-200italic - latin-ext_latin */
@font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 200;
    src: url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-200italic.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-200italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-200italic.woff2') format('woff2'), /* Super Modern Browsers */
        url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-200italic.woff') format('woff'), /* Modern Browsers */
        url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-200italic.ttf') format('truetype'), /* Safari, Android, iOS */
        url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-200italic.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
}

/* ibm-plex-sans-italic - latin-ext_latin */
@font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 400;
    src: url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-italic.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
        url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-italic.woff') format('woff'), /* Modern Browsers */
        url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
        url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-italic.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
}

/* ibm-plex-sans-500 - latin-ext_latin */
@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-500.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-500.woff2') format('woff2'), /* Super Modern Browsers */
        url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-500.woff') format('woff'), /* Modern Browsers */
        url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
        url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-500.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
}

/* ibm-plex-sans-600 - latin-ext_latin */
@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-600.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-600.woff2') format('woff2'), /* Super Modern Browsers */
        url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-600.woff') format('woff'), /* Modern Browsers */
        url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
        url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-600.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
}

/* ibm-plex-sans-500italic - latin-ext_latin */
@font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 500;
    src: url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-500italic.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
        url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-500italic.woff') format('woff'), /* Modern Browsers */
        url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
        url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-500italic.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
}

/* ibm-plex-sans-700 - latin-ext_latin */
@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
        url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-700.woff') format('woff'), /* Modern Browsers */
        url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
        url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-700.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
}

/* ibm-plex-sans-600italic - latin-ext_latin */
@font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 600;
    src: url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-600italic.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
        url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-600italic.woff') format('woff'), /* Modern Browsers */
        url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
        url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-600italic.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
}

/* ibm-plex-sans-700italic - latin-ext_latin */
@font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 700;
    src: url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-700italic.eot'); /* IE9 Compat Modes */
    src: local(''),
        url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
        url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-700italic.woff') format('woff'), /* Modern Browsers */
        url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
        url('../fonts/ibm-plex-sans/ibm-plex-sans-v14-latin-ext_latin-700italic.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
}